<template>
  <FocusTrap>
    <div class="card" id="online_card">

      <div class="card-header header-elements-sm-inline py-sm-0" >
        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Logged User's List</h5>
        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="table-responsive">
        <table ref="user_table" id="user_table" class="table table-bordered table-columned table-hover">
          <thead>
            <tr>
              <th style="width: 40px;">S.No</th>
              <th >Name</th>
              <th >E Mail</th>
              <th style="width: 45px;">#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user,i) in loggedusers" :key="user.id" style="height: 30px;padding: 0px;">
              <td style="width: 40px;">{{ i + 1}}</td>
              <td >{{user.name}}</td>
              <td >{{user.email}}</td>
              <td style="width: 45px;">
                <button type="button" class="btn btn-primary-100 text-primary border-primary btn-icon ml-2" @click="logout_user(user.id)" ><i class="icon-pin-alt"></i></button>
              </td>
            </tr>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        </div>
      </div>
      <!-- End of Context Menu -->


    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'OnlineUsers',
  components: {
  },
  store,
  data () {
    return {
      loggedusers: [],
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    self.loadData();

    self.$refs.user_table.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        if ($(e.target).text() === 'Refresh') {
          self.loadData();
        }
      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#user_table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    });

  },
  methods: {
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#online_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.loggedusers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/user/loggedusers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.loggedusers = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#online_card').unblock();
      });
    },
    logout_user(user_id){
      alert(user_id);
    },

  }
}
</script>

<style scoped>
</style>
